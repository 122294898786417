@import 'scss/helpers';

@mixin link-state() {
  background-color: $navbar-color-link;
  color: $cyan;
  border-radius: 0 35px 35px 0;
}

.sidebar {
  width: pixels-to-rem(290);
  background-color: $bg-leftbar-dark;
  bottom: 0;
  padding: 20px 0;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  top: pixels-to-rem(70);
  box-shadow: 0 1px 1px $navbar-box-shadow;
  z-index: 900;

  &_small {
    width: pixels-to-rem(70);

    @include media-breakpoint-down(md) {
      width: pixels-to-rem(290) !important;
    }
  }

  @include media-breakpoint-down(md) {
    width: pixels-to-rem(70);
    top: pixels-to-rem(60);
  }
}

.menu_list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #023182;
}

.title {
  padding: 10px 20px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: rgb(35, 37, 155);
  font-weight: 600;

  @include media-breakpoint-down(md) {
    display: none;

    &.hide {
      display: block !important;
    }
  }
}

.item_link {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
  margin-bottom: pixels-to-rem(10);
  margin-right: rem-to-pixels(1);
  color: $body-color;

  &:hover {
    @include link-state();
  }

  &__active {
    @include link-state();
  }
}

.link_label {
  @include hide-down(md);

  &__small {
    @include hide-up(md);
  }

  &__show {
    @include show-down();
  }
}

.subItem {
  &_container {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;
    margin-bottom: pixels-to-rem(10);
    margin-right: rem-to-pixels(1);
    cursor: pointer;

    &:hover {
      @include link-state();

      background-color: $navbar-color-item;
      color: $gray-500 !important;
    }
  }

  &_list {
    list-style: none;
    padding: 0;
  }

  &_label {
    @include hide-down(md);

    &__small {
      @include hide-up(md);
    }

    &__show {
      @include show-down();
    }
  }

  &_icon {
    color: $yellow;
    font-size: pixels-to-rem(16);

    @include hide-down(md);

    &__small {
      @include hide-up(md);
    }

    &__show {
      @include show-down();
    }
  }

  &_link {
    display: flex;
    align-items: center;
    color: $bg-leftbar;
    padding: 10px 43px;
    margin-bottom: pixels-to-rem(10);
    margin-right: rem-to-pixels(1);

    @include media-breakpoint-down(md) {
      padding: 10px 20px;
    }

    &__small {
      @include media-breakpoint-up(md) {
        padding: 10px 20px;
      }
    }

    &__show {
      @include media-breakpoint-down(md) {
        padding: 10px 20px;
      }
    }

    &:hover {
      @include link-state();
    }

    &__active {
      @include link-state();
    }
  }
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

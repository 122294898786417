$color-white: #f3f3f3;
$back-ground-blue: #4c77af;

.resultsTable {
    overflow-x: auto;
}
.resultsTable table {
    margin-top: 40px;
    width: 100%;
    box-shadow: 10px 10px;
}
.resultsTable th {
    height: 50px;
    background-color: rgb(95, 132, 141);
    color: $color-white;
}
.resultsTable tr{
    height: 20px;
    color: black;
}
.resultsTable tr:nth-child(even) {
    background-color: $color-white;
}
.resultsTable th, td {
    white-space: nowrap;
    padding: 10px;
    text-align: left;
}
.resultsTable table, th, td {
    border: 1px solid rgb(184, 184, 184);
}
td.numeric {
    padding: 10px;
    text-align: right;
}
button {
    border: none;
    color: white;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}
.newButton {
    background-color: #f0940b; /* Green */
}
.searchButton {
    background-color: $back-ground-blue; /* Green */
}
.saveButton {
    background-color: $back-ground-blue; /* Green */
}
.divPaginator {
    display: flex;
    position: absolute;
    right: 25px;
    margin-top: 15px;
}
.divPaginator p {
    vertical-align: baseline;
}


ul.issue-list{
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  margin-bottom: 30px;
}
ul.issue-list li{
  height: 40px;
  border-bottom: 1px solid #E7E7E7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
ul.issue-list li .name{
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  color: #36404a;
}
ul.issue-list .remove{
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border:0px;
  background-color: transparent;
}
ul.issue-list li .remove i{
  color: #979797;
  font-size: 24px;
}
div.left{
  float: left;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $color-white;
  background-clip: border-box;
  border: 1px solid #36404a;
  border-radius: .25rem;
}

.divFilter{
  padding: 15px;
  background-color: $color-white;
  border-radius: 10px;
  border: 0px solid #dbdbdb;
}

.table-white{
  padding: 15px;
  background-color: $color-white;
  border-radius: 5px;
}

.btn-eject
{
  color: $color-white;
  background-color: $back-ground-blue;
  border-color: $back-ground-blue;

}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #E9EEF1;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,0);
  border-radius: .2rem;
  outline: 0;
}

.black-label{
  width: 100%;
  color: black;
}

.white-select{
  background-color: white;
  width: 100%;
  border-radius: .2rem;
  border: 2 solid rgba(0,0,0,0);
  height: 40px;
}

.white-month-picker{
  background-color: white;
  width: 100%;
  border-radius: .2rem;
  border: 1 solid rgba(0,0,0,0);
  height: 40px;
}

body {
  font-family: sans-serif;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;
  background-color: white;
  color: black;
  width: 95%;
  position: absolute;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #4192c0;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

.inner-addon input {
  width: 100%;
  padding-right: 40px;
  display: inline-block;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 3px;
}

/* style icon */
.inner-addon i {
  position: absolute;
  right: 15px;
  top: 15px;
  color: gray;
}

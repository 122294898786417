.cellAlignmentLeft{
    color: black;
}
.cellAlignmentRight{
    text-align: right;
    color: black;
}
.headerAlignmentCenter{
    text-align: center;
}
.validNumber{
    text-align: right;
    color: green;
}
.invalidNumber{
    text-align: right;
    color: red;
}
.divData{
    margin: 20px 0px;
}
.header{
    color:rgb(112, 112, 112);
}
.subHeader{
    color:rgb(119, 119, 119);
}
.divFilter{
    padding: 15px;
    background-color: #ffffff;
    border-radius: 5px;
}

.dropdown-menu.show {
  display: block;
  background-color: #ffffff;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .375rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #000000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

@import 'scss/helpers';

.page {
  margin-left: pixels-to-rem(290);
  overflow: hidden;
  padding: 0 15px 65px 15px;
  min-height: 80vh;
  margin-top: pixels-to-rem(70);

  &_small {
    margin-left: pixels-to-rem(70);
  }

  @include media-breakpoint-down(md) {
    margin-left: pixels-to-rem(70);
  }
}

table tr td {
  border: transparent;
}
.contractGroupPanel {
  margin: auto;
  position: relative;
  width: auto;
  border: 3px solid rgb(219, 219, 219);
  padding: 10px;
  background: #999999;
  color: white;
  box-shadow: 10px 10px rgb(226, 226, 226);
}
.contractGroupPanel input[type=text]{
width: 350px;
padding: 5px 20px;
margin: 5px 0;
box-sizing: border-box;
}

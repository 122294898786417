@import './app.scss';
@import './functions/util';

@mixin hide-up($size: md) {
  @include media-breakpoint-up($size) {
    display: none;
  }
}

@mixin hide-down($size: md) {
  @include media-breakpoint-down($size) {
    display: none;
  }
}

@mixin show-down($size: md) {
  @include media-breakpoint-down($size) {
    display: block;
  }
}

@mixin show-up($size: md) {
  @include media-breakpoint-up($size) {
    display: block;
  }
}

@import 'scss/helpers';
$color-int: #014C97;
$color-back: #F2F5F9;
$color-white: #FFFFFF;

.root {
  background-color:$color-white;
  width: 100%;
  height: pixels-to-rem(70);
  position: fixed;
  z-index: 1000;
}

.nav {
  display: flex;
  position: relative;
  align-items: center;

}


.menu {
  border: none;
  color: $color-int;
  height: pixels-to-rem(70);
  line-height: pixels-to-rem(70);
  width: pixels-to-rem(60);
  background-color: transparent;
  font-size: pixels-to-rem(24);

  cursor: pointer;
}

.spacer {
  flex: 1 1 auto;
}

.logo_container {
  display: flex;
  align-items: center;
  height: pixels-to-rem(70);
  width: pixels-to-rem(290);
  float: left;
  background-color: $bg-leftbar-dark;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  @include media-breakpoint-down(md) {
    width: pixels-to-rem(70);
  }

  &__small {
    width: pixels-to-rem(70);

    @include media-breakpoint-down(md) {
      width: pixels-to-rem(290) !important;
    }
  }
}

.logo_lg {
  display: block;
  padding: 0 42px;

  @include media-breakpoint-down(md) {
    display: none;

    &.hide {
      display: block !important;
    }
  }
}

.logo_small {
  display: none;
  height: pixels-to-rem(50);
  padding: pixels-to-rem(16);

  @include media-breakpoint-down(md) {
    display: block;

    &.show {
      display: none !important;
    }
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.dropdown_wrapper {
  padding: 0 3rem;
  

}

.dropdown {
  & > button {
    background-color: $color-back;
    border: none;
    color: $color-int;

}
 
 

  button:hover{
    background-color: $color-back; 
    color: $color-int;
  
  }

  .fa-chevron-up
  {
    color: $color-int;
   
  }

  background-color: $color-back; 
}

 .dropdownitem{
  
    background-color: $color-back; 
    color:$color-int;
    padding:-1px;
    :hover{
      background-color:$color-back;
      
      color:$color-int;
    }
    :link{
      background-color:$color-back;
      color:$color-int;
    }
   
  }

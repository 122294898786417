$bg-leftbar-dark: #0d3544;
$base-color: #ced4da;
$base-color_search: #8ab6da;
$light-background: lighten(desaturate($base-color, 50%), 12.5%);

.actionButton {
    background-color: #0d3544; /* Green */
    border: none;
    color: white;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.searchText{
    margin: auto;
    height: 50px;
    position: relative;
    width: 50%;
    border: 3px solid rgb(219, 219, 219);
    padding: 10px;
    border-radius: 0px 10px;
    background: $base-color_search;
    color:$bg-leftbar-dark;
}
.searchText p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.notFoundMessage {
    margin: auto;
    height: 50px;
    position: relative;
    width: 50%;
    border: 3px solid rgb(219, 219, 219);
    padding: 10px;
    border-radius: 0px 10px;
    background: #c8625a;
    color: white;
}
.notFoundMessage p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

// Variables

//
// custom-variables
//

//Background left-sidebar
$bg-leftbar: #040c7c;
$bg-leftbar-dark: #F2F5F9;
// Leftbar width
$leftbar-width: 240px;
$leftbar-width-collapsed: 70px;

// Small Leftbar Width
$leftbar-width-sm: 160px;

// Boxed layout width
$boxed-layout-width: 1300px;

// Logo box
$logo-box-background: #0e0303;

// Dark leftbar menu color
$menu-item-color-dark: #9097a7;
$menu-item-hover-color-dark: #030303;
$menu-item-active-color-dark: #0e0303;

//Menu item colors
$menu-item: #0d3e50;
$menu-item-hover: #0d3e50;
$menu-item-active: #12556d;
$menu-sub-item-active: #0d3544;

//Rightbar Width
$rightbar-width: 280px;

//Topbar Background
$bg-topbar-light: #0c303d;
$bg-topbar-dark: #323b44;

// Topbar Height
$topbar-height: 70px;

// Footer
$footer-bg: #DFEBF4;

// Secondary font
$font-family-secondary: 'Roboto', sans-serif;

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

// Page title box
$page-title-bg: rgb(10, 12, 14);

//
// Color system
//

$gray-100: #276CB9;
$gray-200: #0B4FC6;
$gray-300: #0B4FC6;
$gray-400: #0B4FC6;
$gray-500: #0B4FC6;
$gray-600: #000000;
$gray-700: #101111;
$gray-800: #0e0909;
$gray-900: #040505;

$grays: ();
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: rgb(4, 51, 131);
$indigo: #675aa9;
$purple: #6559cc;
$pink: #f672a7;
$red: #f1556c;
$orange: #fd7e14;
$yellow: #f7b84b;
$green: #000000;
$teal: #080a0a;
$cyan: #020e79cb;
$white: #020e79cb;

$colors: ();
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $blue;
$secondary: $gray-400;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$blue: $blue;
$light: $gray-300;
$dark: $gray-500;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'pink': $pink,
    'purple': $purple,
  ),
  $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-800;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    15: 15%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-100;
$body-color: #276CB9;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Shadow

$box-shadow-sm: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
$box-shadow: 0 0 15px 0 rgba(40, 48, 55, 0.6);
$box-shadow-lg: 0 0 45px 0 rgba($black, 0.12);

// Components
//
$component-active-bg: $primary;

$caret-width: 0.25em;

$border-color: $gray-300;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 24px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-html-size: 16px;
$font-size-base: 0.9rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$font-weight-bold: 700;

$headings-margin-bottom: $spacer;
$headings-font-weight: 400;
$headings-line-height: 1.1;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 0.9375rem;
$h6-font-size: 0.75rem;

$display-line-height: $headings-line-height;

$lead-font-size: ($font-size-base * 1.25);

$small-font-size: 0.75rem;

$text-muted: $gray-500;

$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: $gray-200;

$list-inline-padding: 6px;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.85rem;
$table-cell-padding-sm: 0.5rem;

$table-color: $body-color;
$table-hover-color: $table-color;

$table-accent-bg: lighten($gray-200, 2%);
$table-hover-bg: lighten($gray-200, 2%);

$table-border-color: $gray-300;

$table-head-bg: lighten($gray-200, 2%);
$table-head-color: $gray-700;

$table-dark-bg: $gray-100;
$table-dark-border-color: lighten($gray-100, 7.5%);
$table-dark-color: $gray-600;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 0.9rem;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.15rem;
$input-btn-focus-color: rgba($primary, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.28rem;
$input-btn-padding-x-sm: 0.8rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-size: $input-btn-font-size;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-font-weight: $font-weight-normal;
$btn-focus-width: 0.15rem;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;

$btn-link-disabled-color: $gray-500;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: 0.15rem;
$btn-border-radius-lg: 0.15rem;
$btn-border-radius-sm: 0.15rem;

// Forms
$input-bg: lighten($gray-200, 5%);
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: $input-btn-font-size;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-disabled-bg: $gray-100;

$input-color: $body-color;
$input-border-width: 1px;
$input-border-color: $gray-300;
$input-border-radius: 0.2rem;

$input-focus-bg: $gray-300;
$input-focus-color: $white;
$input-focus-box-shadow: none;
$input-focus-border-color: lighten($gray-300, 5%);

$input-placeholder-color: $gray-500;
$input-plaintext-color: $input-color;

$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-300;
$input-group-addon-border-color: $input-border-color;

$custom-select-padding-y: 0.45rem;
$custom-select-padding-x: 0.9rem;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;

$custom-control-indicator-bg: $gray-300;
$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-border-color: lighten($gray-300, 7.5%);
$custom-control-indicator-checked-bg: $primary;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color('primary'), 0.5);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-select-border-width: $input-border-width;
$custom-select-color: $input-color;
$custom-select-bg: $input-bg;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-select-indicator-color: $gray-500;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
  '#',
  '%23'
);
$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center /
  $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: none;
$custom-file-height-inner: $input-height-inner;

$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: none;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: 'Browse',
) !default;

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  '#',
  '%23'
);

$form-validation-states: ();
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid,
    ),
  ),
  $form-validation-states
);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg: $gray-200;
$dropdown-color: $body-color;
$dropdown-padding-y: 0.25rem;
$dropdown-border-color: darken($light, 3%);

$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: lighten($gray-200, 2%);
$dropdown-link-hover-color: $headings-color;

$dropdown-link-active-color: $white;
$dropdown-link-active-bg: lighten($gray-200, 2%);

$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: 1.2rem;

$dropdown-header-color: inherit;

// Navs
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-tabs-border-color: lighten($gray-200, 5%);
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: lighten($gray-200, 2%);
$nav-tabs-link-active-border-color: lighten($gray-200, 5%);

$nav-tabs-link-hover-border-color: lighten($gray-200, 5%);

// Navbar

$navbar-light-brand-color: $white;
$navbar-light-brand-hover-color: $white;
$navbar-box-shadow: rgba(138, 146, 154, 0.1);
$navbar-color-link: rgba(55, 205, 230, 0.2);
$navbar-color-item: rgba(138, 146, 154, 0.2);

// Pagination

$pagination-color: $gray-700;
$pagination-bg: $gray-300;
$pagination-border-color: lighten($gray-300, 5%);

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;

$pagination-hover-color: $gray-700;
$pagination-hover-bg: $gray-400;
$pagination-hover-border-color: $gray-400;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-bg: $gray-400;
$pagination-disabled-border-color: $gray-400;
$pagination-disabled-color: $gray-700;

// Cards

$card-bg: $gray-200;
$card-spacer-x: $spacer;
$card-spacer-y: $spacer * 2/3;
$card-border-width: 1px;
$card-border-color: $gray-200;
$card-border-radius: 0.25rem;
$card-cap-bg: lighten($gray-200, 5%);
$card-columns-margin: $grid-gutter-width;
$card-columns-gap: $grid-gutter-width;

$card-group-margin: $grid-gutter-width / 2;
$card-deck-margin: $card-group-margin;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-color: $gray-100;
$tooltip-bg: $gray-900;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-border-radius: 0.2rem;
$tooltip-padding-y: 0.4rem;
$tooltip-padding-x: 0.8rem;
$tooltip-font-size: $font-size-base;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Popovers
$popover-bg: $gray-100;

$popover-border-color: $gray-400;
$popover-header-bg: $gray-100;
$popover-border-color: $gray-300;
$popover-border-radius: $border-radius;
$popover-header-padding-y: 0.7rem;
$popover-header-padding-x: 0.8rem;
$popover-font-size: $font-size-base;
$popover-arrow-outer-color: $gray-400;

$popover-header-color: $headings-color;

$popover-body-color: $body-color;
$popover-arrow-color: $popover-bg;

// Badges

$badge-font-weight: $font-weight-bold;
$badge-font-size: 75%;

// Modals
$modal-content-bg: $gray-200;
$modal-header-border-color: $gray-300;
$modal-footer-border-color: $gray-300;
$modal-content-border-width: 0;
$modal-content-border-color: transparent;
$modal-content-border-radius: 0.2rem;
$modal-backdrop-bg: $gray-900;

// Progress bars
$progress-bg: $gray-300;

$progress-height: 0.75rem;
$progress-bar-bg: theme-color('primary');

// List group

$list-group-bg: $gray-200;
$list-group-border-color: rgba($white, 0.05);
$list-group-active-bg: $component-active-bg;

// Image thumbnails

$thumbnail-bg: lighten($gray-200, 5%);
$thumbnail-border-color: $gray-300;

// Breadcrumbs

$breadcrumb-padding-y: $spacer/1.5;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider: quote('\F142');
$breadcrumb-divider-color: $gray-500;
$breadcrumb-active-color: $gray-500;

// Close
$close-color: $gray-800;
$close-font-size: 1.4rem;
$close-text-shadow: none;

// Code

$code-color: $pink;

// Toast
$toast-header-background-color: lighten($gray-200, 2.5%);
$toast-border-color: lighten($gray-200, 2.5%);
$toast-header-color: $gray-800;
$toast-background-color: rgba($gray-100, 0.7);

// Jumbotron

$jumbotron-bg: $gray-200;

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function pixels-to-rem($pixels) {
  $rems: strip-unit($pixels) / strip-unit($font-html-size);
  @return #{$rems}rem;
}

@function rem-to-pixels($rem) {
  $px: strip-unit($rem) * strip-unit($font-html-size);
  @return $px * 1px;
}
